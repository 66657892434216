import { PROFILE_ROLE_FULL_FRAGMENT } from '@/graphql/_Fragments/ProfileRole/Full';

export const COMMUNITY_USER_PROFILE_ROLE_BASE_FRAGMENT = `
  fragment communityUserProfileRoleBaseFragment on CommunityUserProfileRole {
    uid
    schemaCode
    relation
    profileRole {
      ...profileRoleFullFragment
    }
  }
  ${PROFILE_ROLE_FULL_FRAGMENT}
`;
